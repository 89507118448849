import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectClienteConPotenciales (Vue, filter, search, sorter, page) {
    const apiFilter = new APIFilter()
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.cliente.searchConPotencialesFilter(search))
    }
    if (filter.clienteNombre.value) {
      apiFilter.addILike(filter.clienteNombre.field, filter.clienteNombre.value)
    }
    if (filter.razonSocial.value) {
      apiFilter.addILike(filter.razonSocial.field, filter.razonSocial.value)
    }
    if (filter.cif.value) {
      apiFilter.addILike(filter.cif.field, filter.cif.value)
    }
    const resp = await Vue.$api.call('cliente.selectConPotenciales', {
      page,
      filter: apiFilter,
      sorter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectClienteConPotencialesSinFiltroGeneral (Vue, search) {
    const apiFilter = new APIFilter()
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.cliente.searchConPotencialesFilter(search))
    }
    const resp = await Vue.$api.call('cliente.selectConPotencialesSinFiltroGeneral', {
      filter: apiFilter,
    })
    return resp.data.result.dataset
  },
  async selectClienteConPotencialesRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('id', pks)
    const resp = await Vue.$api.call('cliente.selectConPotenciales', {
      filter: apiFilter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
