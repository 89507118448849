<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron clientes"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :load="loadItems"
      :showing-sorter.sync="showingDialogs.sorter"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      @click-item="clickListItem"
    >
      <template
        #listItem="slotProps"
      >
        <cliente-con-potenciales-list-item
          :item="slotProps.item"
        />
      </template>
    </b10-list>
    <b10-fab-button
      v-if="hasInsertPerm(permissions.clientePotencial.id)"
      @click="clickAdd"
    />
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './ClienteConPotencialesListData'
import ClienteConPotencialesListItem from './components/ClienteConPotencialesListItem'
import { get } from 'vuex-pathify'

const pageStoreName = 'pagesClienteConPotencialesList'

export default {
  components: {
    ClienteConPotencialesListItem,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Cartera de clientes'
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectClienteConPotenciales(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
        )
        await this.loadItemsBase(dataset, metadata)
        if (dataset.length === 0 && this.search) {
          const datasetClientesSinFiltro = await Data.selectClienteConPotencialesSinFiltroGeneral(
            this,
            this.search,
          )
          if (datasetClientesSinFiltro.length > 0) {
            this.$alert.showSnackbarError(
              `Existen clientes (${datasetClientesSinFiltro.length}) que coinciden con los criterios de búsqueda pero no puedes visualizarlos`
            )
          }
        }
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        const [dataset] = await Data.selectClienteConPotencialesRows(this, dirty.modified)
        await this.reloadItemsBase(dataset, dirty, 'id')
      } finally {
        this.loadingData = false
      }
    },
    clickListItem (item) {
      this.rememberState = true
      this.rememberScroll = true
      if (item.data.tipo === 'cliente_potencial') {
        this.$appRouter.push({
          name: 'clientespotenciales__cliente-potencial-view',
          params: {
            idcliente_potencial: item.data.id,
          }
        })
      } else if (item.data.tipo === 'cliente') {
        this.$appRouter.push({
          name: 'clientes__cliente-view',
          params: {
            idcliente: item.data.id,
          }
        })
      }
    },
    clickAdd () {
      // sin remember porque agrega un registro
      this.$appRouter.push({
        name: 'clientespotenciales__cliente-potencial-add'
      })
    },
  },
}
</script>
